import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UIState {
  isReadyToPlay: boolean;
  iframeSrc: string;
  isLoggedIn: boolean;
  isDisconnecting: boolean;
  isFullscreen: boolean;
  isMobile: boolean;
  isMobileMenu: boolean;
  refetchGameState: boolean;
}

const initialState: UIState = {
  isReadyToPlay: false,
  iframeSrc: '',
  isLoggedIn: false,
  isDisconnecting: false,
  isFullscreen: false,
  isMobile: false,
  isMobileMenu: false,
  refetchGameState: false
};

const uiSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    setIsReadyToPlay: (state, action: PayloadAction<boolean>) => {
      state.isReadyToPlay = action.payload;
    },
    setIframeSrc: (state, action: PayloadAction<string>) => {
      state.iframeSrc = action.payload;
    },
    setIsLoggedIn: (state, action: PayloadAction<boolean>) => {
        state.isLoggedIn = action.payload;
    },
    setIsDisconnecting: (state, action: PayloadAction<boolean>) => {
      state.isDisconnecting = action.payload;
  },
    setIsFullscreen: (state, action: PayloadAction<boolean>) => {
      state.isFullscreen = action.payload;
    },
    setIsMobile: (state, action: PayloadAction<boolean>) => {
      state.isMobile = action.payload;
    },
    setIsMobileMenu: (state, action: PayloadAction<boolean>) => {
        state.isMobileMenu = action.payload;
    },
    setRefetchGameState: (state, action: PayloadAction<boolean>) => {
      state.refetchGameState = action.payload;
    },
    resetUI: () => initialState,  // Reset to initial state
  },
});

export const { setIsReadyToPlay, setIframeSrc, setIsLoggedIn, setIsDisconnecting, setIsFullscreen, setIsMobile, setIsMobileMenu, setRefetchGameState, resetUI } = uiSlice.actions;

export default uiSlice.reducer;