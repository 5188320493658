import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define the structure of the sessionKey object
export interface SessionKey {  // Export SessionKey so it can be used in other files
  signerAddress: string;
  expirationDate: string; // You can convert this to a Date type if necessary
  approvedCallTargets: string[];
  nativeTokenLimitPerTransaction: string;
  startDate: string; // You can convert this to a Date type if necessary
}

interface WalletState {
  smartWalletAddress: string | null;
  personalWalletAddress: string | null;
  personalWalletType: string | null;
  sessionKey: SessionKey | null;  // Updated to match the expected object type
  isSigningSessionKey: boolean;
  hasValidSessionKey: boolean;
  balance: {
    smart: number;
    personal: number;
    smartBamboo: number;
    personalBamboo: number;
  };
  l2Name: string | null;
  initialFetch: boolean;
}

const initialState: WalletState = {
  smartWalletAddress: null,
  personalWalletAddress: null,
  personalWalletType: null,
  sessionKey: null,  // Updated type
  isSigningSessionKey: false,
  hasValidSessionKey: false,
  balance: {
    smart: 0,
    personal: 0,
    smartBamboo: 0,
    personalBamboo: 0,
  },
  l2Name: null,
  initialFetch: true,
};

const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setSmartWalletAddress: (state, action: PayloadAction<string | null>) => {
      state.smartWalletAddress = action.payload;
    },
    setPersonalWalletAddress: (state, action: PayloadAction<string | null>) => {
      state.personalWalletAddress = action.payload;
    },
    setPersonalWalletType: (state, action: PayloadAction<string | null>) => {
      state.personalWalletType = action.payload;
    },
    setSessionKey: (state, action: PayloadAction<SessionKey | null>) => {  // Ensure it accepts a SessionKey or null
      state.sessionKey = action.payload;
    },
    setIsSigningSessionKey: (state, action: PayloadAction<boolean>) => {
      state.isSigningSessionKey = action.payload;
    },
    setHasValidSessionKey: (state, action: PayloadAction<boolean>) => {
      state.hasValidSessionKey = action.payload;
    },
    setBalance: (state, action: PayloadAction<{ 
      smart: number; 
      personal: number; 
      smartBamboo: number; 
      personalBamboo: number; 
    }>) => {
      state.balance = action.payload;
    },
    setL2Name: (state, action: PayloadAction<string | null>) => {
      state.l2Name = action.payload;
    },
    setInitialFetch: (state, action: PayloadAction<boolean>) => {
      state.initialFetch = action.payload;
    },
    resetWallet: () => initialState,  // Reset to initial state
  },
});

export const {
  setSmartWalletAddress,
  setPersonalWalletAddress,
  setPersonalWalletType,
  setSessionKey,
  setIsSigningSessionKey,
  setHasValidSessionKey,
  setBalance,
  setL2Name,
  setInitialFetch,
  resetWallet,
} = walletSlice.actions;

export default walletSlice.reducer;
