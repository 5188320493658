import { configureStore } from '@reduxjs/toolkit';
import walletReducer from './slices/walletSlice';
import uiReducer from './slices/uiSlice';
import authReducer from './slices/authSlice';
import winsReducer from './slices/winsSlice';

const store = configureStore({
  reducer: {
    wallet: walletReducer,
    ui: uiReducer,
    auth: authReducer,
    wins: winsReducer
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;