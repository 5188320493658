// app/layout.js
"use client";

import { ThirdwebProvider } from "thirdweb/react";
import { Provider } from "react-redux"; // Import the Redux Provider
import store from "../utils/redux/store"; // Default import for store
import './styles/globals.css'; // Import global styles

export default function RootLayout({ children }) {
  return (
    <html lang="en">
      <head>
        <link rel="stylesheet" href="https://use.typekit.net/uub2fsf.css"></link>
        <link rel="stylesheet" href="/assets/css/style.css"></link>
        <link rel="stylesheet" href="/assets/css/shortcodes.css"></link>
        <link rel="stylesheet" href="/assets/css/responsive.css"></link>
      </head>
      <body>
        <Provider store={store}> {/* Wrap Redux Provider here */}
          <ThirdwebProvider
            clientId={process.env.NEXT_PUBLIC_THIRDWEB_CLIENT_ID}
            supportedChains={[process.env.NEXT_PUBLIC_THIRDWEB_CHAIN_ID]}
            activeChain={process.env.NEXT_PUBLIC_THIRDWEB_CHAIN_ID}
            dAppMeta={{
              name: "PandaPlinko.io",
              url: "https://pandaplinko.io",
              description: "The home of betting on $BAMBOO",
              logoUrl: "https://s2.coinmarketcap.com/static/img/coins/64x64/32180.png",
            }}
          >
            {children}
          </ThirdwebProvider>
        </Provider>
      </body>
    </html>
  );
}
