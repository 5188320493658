import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

interface Win {
  username: string;
  bet: number;
  win_value: number;
  multiplier: string;
  created_at: string;
}

interface WinsState {
  wins: Win[];
  loading: boolean;
  error: string | null;
}

const initialState: WinsState = {
  wins: [],
  loading: false,
  error: null,
};

// Async thunk for fetching wins data
export const fetchWins = createAsyncThunk('wins/fetchWins', async () => {
    const response = await axios.get('/api/general/winDisplay', {
      headers: {
        'Cache-Control': 'no-cache',
      },
    });
    return response.data.wins;
  });
  

const winsSlice = createSlice({
  name: 'wins',
  initialState,
  reducers: {
    // You can add custom reducers here if needed
    clearWins: (state) => {
      state.wins = [];
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWins.pending, (state) => {
        state.loading = true;
        state.error = null; // Reset error on new request
      })
      .addCase(fetchWins.fulfilled, (state, action: PayloadAction<Win[]>) => {
        state.loading = false;
        state.wins = action.payload;
      })
      .addCase(fetchWins.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch wins';
      });
  },
});

// Exporting the actions if needed
export const { clearWins } = winsSlice.actions;

// Exporting the reducer as default
export default winsSlice.reducer;
